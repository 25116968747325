import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const bnwSection = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: '#ffffff',
	zIndex: 1,
});

export default bnwSection;

import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import theme from '../theme';

import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { styled } from '@mui/system';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

import BnWSection from '../components/BnWSection';
import BnWContent from '../components/BnWContent';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { Typography } from '@mui/material';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
});

// eslint-disable-next-line
export const UpdatesTemplate = ({
	content,
	contentComponent,
	className,
	description,
	tags,
	title,
	helmet,
	image,
}) => {
	const UpdateContent = contentComponent || Content;

	return (
		<BnWSection
			className='section'
			sx={{ boxShadow: 12, mx: { xs: 1, md: 8 }, mt: { xs: 1, md: 6 } }}
			elevation={3}
		>
			{helmet || ''}
			<BnWContent
				sx={{ px: 2, py: { xs: 2, md: 8 }, m: { xs: 0, sm: '12px 64px 48px' } }}
			>
				<Grid
					container
					spacing={5}
					sx={{ justifyContent: 'space-between', color: '#222222' }}
				>
					<Grid item xs={12} sm={4}>
						<Button
							variant='contained'
							component={Link}
							to='/#toolBox'
							fullWidth
							sx={{ mb: 6, mt: '12px' }}
						>
							close / back to toolbox
						</Button>
						<PreviewCompatibleImage imageInfo={{ image: image, alt: title }} />
						{tags && tags.length ? (
							<>
								<Typography variant='h4'>Tags</Typography>
								<Box className='taglist'>
									{tags.map((tag) => (
										<Chip
											key={tag + `tag`}
											// icon={<FaceIcon />}
											label={tag}
											variant='outlined'
											color='primary'
											component='a'
											// href={`/tags/${kebabCase(tag)}/`}
											clickable
											sx={{ borderRadius: 0, mx: 0.2, my: 0.5 }}
										/>
									))}
								</Box>
							</>
						) : null}
					</Grid>
					<Grid item xs={12} sm={8} className='section'>
						<Typography
							variant='h1'
							className='title is-size-2 has-text-weight-bold is-bold-light'
						>
							{title}
						</Typography>
						{/* <Typography dangerouslySetInnerHTML={{ __html: description.html }} /> */}
						<UpdateContent content={content} className='contentBox' />
					</Grid>
				</Grid>
			</BnWContent>
		</BnWSection>
	);
};

UpdatesTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
};

const Updates = ({ data }) => {
	const { markdownRemark: update } = data;

	return (
		<Layout>
			<UpdatesTemplate
				content={update.html}
				contentComponent={HTMLContent}
				description={update.frontmatter.description}
				helmet={
					<Helmet titleTemplate='%s | Blog'>
						<title>{`${update.frontmatter.title}`}</title>
						<meta
							name='description'
							content={`${update.frontmatter.description.html}`}
						/>
					</Helmet>
				}
				tags={update.frontmatter.tags}
				title={update.frontmatter.title}
				image={update.frontmatter.featuredimage}
			/>
		</Layout>
	);
};

Updates.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
	}),
};

export default Updates;

export const pageQuery = graphql`
	query UpdatesByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description
				featuredimage {
					childImageSharp {
						gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
					}
				}
				tags
			}
		}
	}
`;
